<template>
    <div class="settings-template-main">
        <div class="settings-template-section">
            <menu-tabs @setMenuOption="setMenuOption" @selectedProperty="selectedProperty"></menu-tabs>
            <div class="mr-6" :class="{ 'pt-1': !isPropertySelected }">
                <template v-if="level === 'property' && !property.id">
                    <hb-empty-state message="Please select a Property to continue." />
                </template>
                <template v-else>
                    <hb-data-table-header>
                        <template v-slot:description>
                            <HbTextField search width="400px" v-model="searchTemplate" v-validate="'required|max:45'"
                                data-vv-scope="default" data-vv-name="search-templates" data-vv-as="search-templates"
                                :error="errors.has('default.search-templates')" @click:clear="clearSearch"
                                placeholder="Search Templates" />
                        </template>
                        <template v-slot:actions>
                            <hb-btn color="secondary" small @click="createNewTemplate()"
                                v-if="level !== 'property'">Create
                                New Template</hb-btn>
                            <!-- <hb-btn color="secondary" small @click="assignAndFetch()" v-if="level == 'property'">
                                Assign Template</hb-btn> -->
                        </template>
                    </hb-data-table-header>
                    <hb-data-table item-class="row-class" :headers="headers" multi-sort :items="filteredTemplates"
                        :loading="loading" @click:row="editTemplate" no-data-text="No Templates Found"
                        no-results-text="No Templates Found">
                        <template v-slot:item.actions="{ item }">
                            <hb-menu options align-right>
                                <v-list>
                                    <v-list-item @click="editTemplate(item)" v-if="!item.is_base_template">
                                        <v-list-item-title>Edit</v-list-item-title>
                                    </v-list-item>
                                    <!-- <v-list-item @click="hardDeleteTemplate(item)"
                                        :disabled="!item.is_base_template" v-if="level != 'property'">
                                        <v-list-item-title>Delete</v-list-item-title>
                                    </v-list-item> -->
                                    <v-list-item @click="deleteTemplate(item)"
                                        v-if="level == 'property' && !item.is_base_template">
                                        <v-list-item-title>Unassign</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="duplicateTemplate(item)" :disabled="item.is_base_template">
                                        <v-list-item-title>Duplicate</v-list-item-title>
                                    </v-list-item>
                                    <!-- <v-list-item @click="previewTemplate(item)">
                                        <v-list-item-title>Preview</v-list-item-title>
                                    </v-list-item> -->
                                </v-list>
                            </hb-menu>
                        </template>
                        <!-- <template v-slot:item.content="{ item }">
                            <div>
                                <span class="duplicated-pill">duplicated</span> from {{ item.name }}
                            </div>
                        </template> -->
                        <template v-slot:item.created_user="{ item }">
                            <div class="d-flex flex-column" v-if="!item.is_base_template">
                                <hb-tooltip dashed disabled>
                                    <template v-slot:item>
                                        <span> {{
                                            item.created_modified_by.firstName + " " + item.created_modified_by.lastName
                                            }}</span>
                                    </template>
                                </hb-tooltip>
                                <span v-if="item.created_modified_by">
                                    {{ formatUpdatedAt(item.created_at) }}
                                </span>
                            </div>
                            <div class="d-flex flex-column" v-else>
                                <span class="font-weight-medium">System Generated</span>
                                <!-- <span v-if="item.created_modified_by">
                                    {{ formatUpdatedAt(item.created_at) }}
                                </span> -->
                            </div>
                        </template>
                        <template v-slot:item.category="{ item }">
                            <div>
                                {{ getCatagory(item.category) | capitalize }}
                            </div>
                        </template>
                        <template v-slot:item.type="{ item }">
                            <div>
                                {{ item.type | capitalize }}
                            </div>
                        </template>
                        <template v-slot:item.status="{ item }">
                            <div v-if="item.status == 'active'">
                                <HbIcon mdi-code="mdi-check-circle" small color="rgb(2, 173, 15)" /><span>
                                    {{ item.status }}
                                </span>
                            </div>
                            <div v-else>
                                <HbIcon mdi-code="mdi-check-circle" small /><span>
                                    {{ item.status }}
                                </span>
                            </div>
                        </template>
                        <template v-slot:item.assigned_properties_count="{ item }">
                            <div class="d-flex flex-column " v-if="!item.is_base_template">
                                <hb-tooltip>
                                    <template v-slot:item>
                                        <span>
                                            <span style="border-bottom: 1px dashed #101318; padding-bottom: 2px;">
                                                {{ getPropetyCount(item.propertyIds) }} {{
                                                    item.assigned_properties_count
                                                        <= 1 ? 'Property' : 'Properties' }} </span>

                                            </span>
                                    </template>
                                    <template v-slot:header>
                                        <div v-if="item.assigned_properties_count >= 1">
                                            List of properties using this template.
                                        </div>
                                        <div v-else>
                                            No properties assigned to this template
                                        </div>
                                    </template>
                                    <template v-slot:body>
                                        <div v-for="p in item.propertyIds" :key="p.id">
                                            <span v-if="p">
                                                {{ getPropertyById(p)?.name }}
                                            </span>
                                        </div>
                                    </template>
                                </hb-tooltip>
                            </div>
                            <div v-else>
                                <span></span>
                            </div>
                        </template>
                    </hb-data-table>
                </template>
            </div>
        </div>
        <hb-modal size="medium" title="Unassign Template" v-model="deletePopup" confirmation>
            <template v-slot:content>
                <div v-if="isAssigned" class="py-4 px-6">
                    This template is currently assigned to actions in active processes
                    Do you want to select a replacement template?
                    <div class="mt-5 mb-5">
                        <HbSelect v-model="replacement_template" :items="listOfTemplates" full
                            v-validate="'required|max:45'" data-vv-scope="default" data-vv-name="replacement_template"
                            data-vv-as="replacement_template" :error="errors.has('default.replacement_template')"
                            placeholder="Select Replacement Template">
                        </HbSelect>
                    </div>
                    if no replacement template is selected , any action that had this template applied will be changes
                    to a
                    blank message
                </div>
                <div v-else class="py-4 px-6">
                    Are you sure you want to unassign the selected template?
                </div>
            </template>
            <template v-slot:left-actions>
            </template>
            <template v-slot:right-actions>
                <hb-btn color="destructive" @click="finalDelete()">Unassign </hb-btn>
            </template>
        </hb-modal>
        <hb-modal size="large" title="Assign Template" v-model="assignPopUp">
            <template v-slot:content>
                <hb-data-table-header left-columns="10">
                    <template v-slot:description>
                        <div class="pl-4"> Select any template from the below list and assign to the current selected
                            property</div>
                    </template>
                </hb-data-table-header>
                <hb-data-table show-select item-key="template_id" :headers="headers" :items="fullTemplateList"
                    :loading="loading" v-model="selectedTemplate">
                    <template v-slot:item.created_user="{ item }">
                        <div class="d-flex flex-column">
                            <span> {{ item.created_modified_by.firstName + item.created_modified_by.lastName }}</span>
                            <span v-if="item.created_modified_by">
                                {{ formatUpdatedAt(item.updated_at) }}
                            </span>
                        </div>
                    </template>
                    <template v-slot:item.property_count="{ item }">
                        <div class="d-flex flex-column">
                            <span class="underline-dotted">{{ item.property_count }}&nbsp; Properties</span>
                        </div>
                    </template>
                </hb-data-table>
            </template>
            <template v-slot:left-actions>
            </template>
            <template v-slot:right-actions>
                <hb-btn @click="assignTemplate()">Assign</hb-btn>
            </template>
        </hb-modal>
    </div>
</template>

<script type="text/babel">
import api from '../../../assets/api.js';
import { mapGetters } from "vuex";
import settingsTabMixin from '../../../mixins/settingsTabMixin.js';
import { notificationMixin } from '../../../mixins/notificationMixin.js';
import { EventBus } from '../../../EventBus';
import MenuTabs from '../Common/MenuTabs';
import moment from 'moment';

export default {
    name: "CommunicationTemplates",
    mixins: [settingsTabMixin, notificationMixin],
    data() {
        return {
            level: 'corporate',
            searchTemplate: '',
            replacement_template: '',
            property: {},
            panel: 0,
            defaultTemplateList: [],
            deletePopup: false, // for delete popup
            assignPopUp: false,
            isAssigned: false, // if template is currenlty used or not
            currentItem: null,
            selectedTemplate: [], // asign template checkbox v-model
            loading: false,
            fullTemplateList: [],
            deleteId: '',
            singleSelect: false,
            // TODO : its should be in backend
            items: [
                { text: 'Rent Change', value: 'rent_change' },
                { text: 'Delinquency', value: 'deliquency' },
                { text: 'Maintenance', value: 'maintenance' },
                { text: 'Alerts', value: 'alerts' },
                { text: 'General', value: 'general' },
            ],
        };
    },
    components: {
        MenuTabs
    },
    filters: {
        capitalize: function (value) {
            if (!value) return ''
            value = value.toString()
            if (value.toUpperCase() === 'SMS') {
                return 'SMS'; // If the word is 'SMS', return it in all caps
            } else {
                return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(); // Capitalize only the first letter
            }
        }
    },
    created() {
        EventBus.$on('CommunicationTemplate:refreshList', this.getBaseTemplateData);
    },
    destroyed() {
        EventBus.$off('CommunicationTemplate:refreshList', this.getBaseTemplateData);
    },
    computed: {
        ...mapGetters({
            facilityList: "propertiesStore/filtered",
            properties: "propertiesStore/properties",
        }),
        isPropertySelected() {
            return this.property.id ? true : false;
        },
        isPropertyLevel() {
            return this.level === 'property' ? true : false;
        },
        headers() {
            let headers = [
                { text: "Template Name", value: "name", sortable: true },
                // { text: "Content", value: "content",sortable: false},
                { text: "Category", value: "category", sortable: true },
                { text: "Delivery Type", value: "type", sortable: true },
                { text: "Created By", value: "created_user", sortable: true },
                { text: "Status", value: "status", sortable: true },
                { text: "# Of Properties", value: "assigned_properties_count", sortable: true },
                { text: "", value: "actions", align: "right", sortable: false }
            ];

            if (this.level === 'property') {
                headers = headers.filter(header => header.value !== "assigned_properties_count");
            }

            return headers;
        },
        listOfTemplates() {
            return this.defaultTemplateList.map(obj => obj.name);
        },
        filteredTemplates() {
            if (!this.searchTemplate) {
                // If search input is empty, return full list
                return this.defaultTemplateList.sort((a, b) => {
                    if (a.is_base_template === 1 && b.is_base_template !== 1) {
                        return -1; // a comes before b
                    } else if (a.is_base_template !== 1 && b.is_base_template === 1) {
                        return 1; // b comes before a
                    } else if (a.status === "inactive" && b.status !== "inactive") {
                        return 1; // b comes before a
                    } else if (a.status !== "inactive" && b.status === "inactive") {
                        return -1; // a comes before b
                    } else {
                        return 0; // no change in order
                    }
                });
            }
            // Otherwise, filter the list based on the search input
            return this.defaultTemplateList.filter(item =>
                Object.values(item).some(value =>
                    value !== null && value.toString().toLowerCase().includes(this.searchTemplate.toLowerCase())
                )
            );
        }
    },
    watch: {
        property: {
            async handler(level) {
                // Emit the value to the parent component
                await this.getBaseTemplateData()
            },
            deep: true
        },
    },
    async mounted() {
        await this.getBaseTemplateData()
    },
    methods: {
        getCatagory(cata) {
            const item = this.items.find(item => item.value === cata);
            return item ? item.text : null;
        },
        getPropetyCount(ids) {
            let count = 0;
            ids.forEach(id => {
                if (this.facilityList.find(property => property.id === id)) {
                    count++;
                }
            });
            return count;
        },
        getPropertyById(id) {
            return this.facilityList.find(property => property.id === id);
        },
        async clearSearch() {
            return this.defaultTemplateList;
        },
        async initializeData() {
            // created because of mixin
        },
        formatUpdatedAt(updatedAt) {
            if (updatedAt) {
                // Adjust the format as needed
                return moment.utc(updatedAt).utcOffset("+05:30").format("MMM D, YYYY @ hh:mma");
            }
            return ""; // Or any default value for empty updated_at
        },
        async selectedProperty(property) {
            if (this.property != property) {
                this.property = property;
            }
        },
        setMenuOption(option) {
            this.level = option
            this.property = ''
            this.searchTemplate = ''
        },
        async getBaseTemplateData() {
            let resp;
            this.loading = true
            if (this.isPropertySelected) {
                resp = await api.get(this, api.TEMPLATE_MANAGER + `templates` + `?property_id=${this.property.id}`);
            }
            else {
                resp = await api.get(this, api.TEMPLATE_MANAGER + `templates`);
            }
            this.defaultTemplateList = resp.templates.filter(template => template.status == 'active')
            // only active template will show 
            this.loading = false
        },
        deleteTemplate(item) {
            this.deletePopup = true
            this.deleteId = item.template_id
        },
        async finalDelete() {
            try {
                let resp;
                if (this.isPropertySelected) {
                    resp = await api.delete(this, api.TEMPLATE_MANAGER + `template/${this.deleteId}` + `?property_id=${this.property.id}`);
                }
                else {
                    resp = await api.delete(this, api.TEMPLATE_MANAGER + `template/${this.deleteId}`);
                }
                this.deletePopup = false
                EventBus.$emit('CommunicationTemplate:refreshList')
                this.showMessageNotification({ type: 'success', description: "Template unassigned successfully" });
            } catch (error) {
                console.error(error);
                this.showMessageNotification({ type: 'error', description: "Something went wrong" });
            }
        },
        createNewTemplate() {
            this.currentItem = {
                isNew: true
            };
            EventBus.$emit('HB-Navigation:CommunicationTemplate', this.currentItem);
        },
        duplicateTemplate(item) {
            // check if it is a base - template from HB
            this.currentItem = {
                item: item,
                propertyId: this.property?.id, // if it is from property tab
                duplicate: true
            };
            EventBus.$emit('HB-Navigation:CommunicationTemplate', this.currentItem);
        },
        async editTemplate(item) {
            //check if it is a base - template from HB
            let resp;
            if (this.isPropertySelected) {
                resp = await api.get(this, api.TEMPLATE_MANAGER + `template/${item.template_id}` + `?property_id=${this.property.id}`);
            }
            else {
                resp = await api.get(this, api.TEMPLATE_MANAGER + `template/${item.template_id}`);
            }
            this.currentItem = {
                item: item,
                propertyId: this.property.id,// if it is from property tab
                data: resp,
                edit: true
            };
            EventBus.$emit('HB-Navigation:CommunicationTemplate', this.currentItem);
            // this.showMessageNotification({ type: 'error', description: "Unable to Edit HB Templates" });
        },
        async assignAndFetch() {
            this.assignPopUp = true
            const resp = await api.get(this, api.TEMPLATE_MANAGER + `templates`);
            this.fullTemplateList = resp;
        },
        assignTemplate() {
            if (this.assignPopUp = true) {

            }
        }
    }
}
</script>
<style scoped></style>